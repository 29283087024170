import Api from "@/api"
import store from "@/store"
import router from "@/router"
import { API_BASEURL } from "@/store/constants"

const FINANCE = "/reports/finance/"
const FINANCE_EXPORT = "/reports/export/finance/"
const RECRUITERS = "/reports/recruiters/"
const DEPARTMENTS = "/reports/departments/"
const NOTE_POST = "/reports/note/"
const NOTE = "/reports/note/?report_id=:report_id"

export default {
    getFinance(page, ordering, query, search, download = false) {
        if (download) {
            return Api.http(true).get(
                `${FINANCE_EXPORT}?${ordering !== "" ? `&ordering=${ordering}` : ""}${query !== "" ? query : ""}${search !== "" ? `&search=${search}` : ""}`,
                {
                    responseType: "blob",
                }
            )
        }
        return Api.http(true).get(
            `${FINANCE}?page=${page}${ordering !== "" ? `&ordering=${ordering}` : ""}${query !== "" ? query : ""}${search !== "" ? `&search=${search}` : ""}`
        )
    },
    getRecruiters() {
        return Api.http(true).get(RECRUITERS)
    },
    getDepartments() {
        return Api.http(true).get(DEPARTMENTS)
    },
    putFinanceReport(data) {
        return Api.http(true).put(`${FINANCE + data.id}/`, data)
    },
    getNote(id) {
        return Api.http(true).get(NOTE.replace(":report_id", id))
    },
    setNote(data) {
        return Api.http(true).post(NOTE_POST, data)
    },
    deleteNote(id) {
        return Api.http(true).delete(`${NOTE_POST + id}/`)
    },
}
