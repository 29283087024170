<template>
    <div>
        <div>
            <!-- input search -->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="outline-primary"
            >
                Columns
            </b-button>
            <b-button
                v-if="query"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="margin-left-10"
                variant="outline-secondary"
                @click="clear()"
            >
                Clear
            </b-button>
            <b-modal
                id="modal-1"
                centered
                title="Columns"
                ok-only
                ok-title="Hide"
            >
                <b-card-text>
                    <div></div>
                    <b-container>
                        <b-row>
                            <b-col v-for="item in columns" :key="item" cols="4">
                                <b-form-checkbox
                                    v-if="item.label !== 'Action'"
                                    v-model="item.hidden"
                                    :value="true"
                                    plain
                                >
                                    {{ item.label }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-text>
            </b-modal>
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input
                            v-model="searchTerm"
                            placeholder="Search"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
                <div class="margin-left-10">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="flat-secondary"
                        v-b-tooltip.hover.top="'Download'"
                        class="btn-icon"
                        @click="download()"
                    >
                        <feather-icon size="1.5x" icon="DownloadIcon" />
                    </b-button>
                </div>
            </div>

            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                }"
                :total-rows="total"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                :isLoading.sync="isLoading"
                @on-per-page-change="onPerPageChange"
            >
                <template slot="table-column" slot-scope="props">
                    <span class="text-nowrap">
                        {{ props.column.label }}
                    </span>
                </template>
                <template slot="column-filter" slot-scope="props">
                    <!-- <slot
            name="column-filter"
            :column="props.column"
            :updateFilters="props.updateFilters"
          >
          </slot> -->
                    <input
                        v-if="props.column.type === 'text'"
                        type="text"
                        class="vgt-input"
                        @keyup.enter="
                            props.updateFilters(
                                props.column,
                                $event.target.value
                            )
                        "
                        @input="
                            props.updateFilters(
                                props.column,
                                $event.target.value
                            )
                        "
                    />
                    <v-select
                        v-else-if="props.column.type === 'select'"
                        v-model="props.column.selected"
                        dir="ltr"
                        label="title"
                        :options="props.column.options"
                        class="select-size-sm"
                        @input="
                            props.column.selected === null
                                ? props.updateFilters(props.column, '')
                                : props.updateFilters(
                                      props.column,
                                      $event.title
                                  )
                        "
                    />
                    <flat-pickr
                        v-else-if="props.column.type === 'date'"
                        :config="{
                            mode: 'range',
                        }"
                        class="vgt-input"
                        @input="props.updateFilters(props.column, $event)"
                    ></flat-pickr>
                    <!-- <vue-slider
            v-else-if="props.column.type === 'number'"
            v-model="props.column.value"
            :min-range="1"
            direction="ltr"
            :max-range="100"
            @change="props.updateFilters(props.column, $event)"
          /> -->
                    <div
                        v-else-if="
                            props.column.type === 'number' &&
                            props.column.model === 1
                        "
                        class="d-flex"
                    >
                        <input
                            type="number"
                            v-model="props.column.value[0]"
                            class="vgt-input min-input-55"
                            @keyup.enter="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                            @input="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                        />
                        <span class="margin-10">to</span>
                        <input
                            type="number"
                            v-model="props.column.value[1]"
                            class="vgt-input min-input-55"
                            @keyup.enter="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                            @input="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                        />
                    </div>
                    <div
                        v-else-if="props.column.type === 'number'"
                        class="d-flex"
                    >
                        <input
                            type="number"
                            v-model="props.column.value"
                            class="vgt-input min-input-55"
                            @keyup.enter="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                            @input="
                                props.updateFilters(
                                    props.column,
                                    props.column.value
                                )
                            "
                        />
                    </div>
                </template>
                <!-- <template
          slot="column-filter"
          slot-scope="props"
        >
          <slot
            name="column-filter"
            :column="props.column"
            :updateFilters="props.updateFilters"
          ></slot>
          <div>
            <b-form-group>
              <v-select
                v-model="selecSelected"
                dir="rtl"
                label="title"
                :options="option"
              />
            </b-form-group>
          </div>
        </template> -->
                <template slot="table-row" slot-scope="props">
                    <div
                        v-if="props.column.field === 'candidate'"
                        class="text-nowrap"
                    >
                        <a :href="props.row.link" target="_blank">
                            <span class="text-nowrap">{{
                                props.row.candidate
                            }}</span>
                        </a>
                    </div>
                    <span
                        v-else-if="props.column.field === 'status.description'"
                    >
                        <b-badge
                            :variant="
                                statusVariant(props.row.status.description)
                            "
                        >
                            {{ props.row.status.description }}
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.field === 'process_stage'">
                        {{ props.row.process_stage }}%
                        <b-progress
                            v-model="props.row.process_stage"
                            :variant="
                                processStageVariant(props.row.process_stage)
                            "
                            max="100"
                        />
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="flat-secondary"
                                class="btn-icon rounded-circle"
                                v-b-tooltip.hover.top="'Edit Info'"
                                v-b-modal.edit-btn
                                @click="edit(props.row)"
                                aria-pressed="false"
                            >
                                <feather-icon icon="EditIcon" />
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="flat-primary"
                                class="btn-icon rounded-circle"
                                v-b-tooltip.hover.top="'Add Note'"
                                aria-pressed="false"
                                v-b-modal.edit-note-btn
                                @click="editNote(props.row)"
                            >
                                <feather-icon icon="MessageCircleIcon" />
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="flat-success"
                                class="btn-icon rounded-circle"
                                v-b-tooltip.hover.top="'Add Project Revenue'"
                                v-b-modal.edit-revenue-btn
                                v-if="
                                    props.row.department.description ===
                                        'META' &&
                                    props.row.status.description === 'Hired'
                                "
                                @click="editRevenue(props.row)"
                                aria-pressed="false"
                            >
                                <feather-icon icon="TrendingUpIcon" />
                            </b-button>
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'project_revenue'"
                        :class="'text-nowrap text-info'"
                    >
                        {{
                            cleanZero(
                                String(props.formattedRow[props.column.field])
                            ) | currency
                        }}
                    </span>
                    <span
                        v-else-if="props.column.field === 'fee_rate'"
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.fee_rate
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{
                                cleanZero(
                                    String(
                                        props.formattedRow[props.column.field]
                                    )
                                )
                            }}
                            {{ props.formattedRow["fee_type"] }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'note'"
                        class="text-nowrap"
                    >
                        <span
                            v-html="
                                (props.formattedRow['note']
                                    ? props.formattedRow['note'].substring(
                                          0,
                                          40
                                      )
                                    : '') +
                                (props.formattedRow['note'] &&
                                props.formattedRow['note'].length > 40
                                    ? '...'
                                    : '')
                            "
                        />
                    </span>
                    <span
                        v-else-if="props.column.field === 'recruiter.name'"
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.recruiter
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </span>
                    <span
                        v-else-if="
                            props.column.field === 'department.description'
                        "
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.department
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'role'"
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.role ? 'bg-table-secondary' : ''
                            "
                        >
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'sent_date'"
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.sent_date
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'start_date'"
                        class="text-nowrap"
                    >
                        <span
                            :class="
                                props.row.data.start_date
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'salary'"
                        :class="
                            'text-nowrap ' +
                            (props.column.field === 'revenue' &&
                            props.formattedRow['status.description'] === 'Hired'
                                ? 'text-success'
                                : '')
                        "
                    >
                        <span
                            :class="
                                props.row.data.salary
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{
                                cleanZero(
                                    String(
                                        props.formattedRow[props.column.field]
                                    )
                                ) | currency
                            }}
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'revenue'"
                        :class="
                            'text-nowrap ' +
                            (props.column.field === 'revenue' &&
                            props.formattedRow['status.description'] === 'Hired'
                                ? 'text-success'
                                : '')
                        "
                    >
                        <span
                            :class="
                                props.row.data.revenue
                                    ? 'bg-table-secondary'
                                    : ''
                            "
                        >
                            {{
                                cleanZero(
                                    String(
                                        props.formattedRow[props.column.field]
                                    )
                                ) | currency
                            }}
                        </span>
                    </span>
                    <span
                        v-else-if="
                            props.column.field === 'next_stage.description'
                        "
                        class="text-nowrap"
                    >
                        {{
                            props.formattedRow[props.column.field] &&
                            props.formattedRow[props.column.field].length > 30
                                ? props.formattedRow[
                                      props.column.field
                                  ].substring(0, 30) + "..."
                                : props.formattedRow[props.column.field]
                        }}
                    </span>
                    <span v-else class="text-nowrap">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <!-- <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div> -->
                        <div class="d-flex align-items-center mb-0 mt-1">
                            Total: {{ total }}
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="
                                    (value) =>
                                        props.pageChanged({
                                            currentPage: value,
                                        })
                                "
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
        <b-modal
            id="edit-btn"
            ref="edit-btn"
            title="Edit Info"
            ok-title="Save"
            size="lg"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            :ok-disabled="isValid()"
            @ok="handleOk"
        >
            <form
                v-if="Object.entries(detail).length > 0"
                ref="form"
                @submit.stop.prevent="handleOK"
            >
                <b-row>
                    <b-col v-if="detail.recruiter" cols="6">
                        <b-form-group
                            label="Recruiter"
                            label-for="name-input"
                            invalid-feedback="Recruiter is required"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['recruiter']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.recruiter +
                                      ' | ' +
                                      detail.data.recruiter_updated_at +
                                      '.'
                            "
                        >
                            <v-select
                                v-model="detail.recruiter.name"
                                dir="ltr"
                                label="title"
                                :options="recruiters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="ID" label-for="id">
                            <b-form-input
                                id="id"
                                v-model="detail.id"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Type"
                            label-for="type"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['department']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.department +
                                      ' | ' +
                                      detail.data.department_updated_at +
                                      '.'
                            "
                        >
                            <v-select
                                v-model="detail.department.description"
                                dir="ltr"
                                label="title"
                                :options="departments"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Candidate" label-for="candidate">
                            <b-form-input
                                id="candidate"
                                v-model="detail.candidate"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Client" label-for="client">
                            <b-form-input
                                id="client"
                                v-model="detail.client"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Role"
                            label-for="role"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['role']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.role +
                                      ' | ' +
                                      detail.data.role_updated_at +
                                      '.'
                            "
                        >
                            <b-form-input id="role" v-model="detail.role" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Sent Date"
                            label-for="sent_date"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['sent_date']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.sent_date +
                                      ' | ' +
                                      detail.data.sent_date_updated_at +
                                      '.'
                            "
                        >
                            <b-form-datepicker
                                id="sent_date"
                                v-model="detail.sent_date"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="detail.status" cols="6">
                        <b-form-group label="Status" label-for="status">
                            <b-form-input
                                id="status"
                                v-model="detail.status.description"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="detail.current_stage" cols="6">
                        <b-form-group
                            label="Current Stage"
                            label-for="current_stage"
                        >
                            <b-form-input
                                id="current_stage"
                                v-model="detail.current_stage.description"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="detail.next_stage" cols="6">
                        <b-form-group label="Next Stage" label-for="next_stage">
                            <b-form-input
                                id="next_stage"
                                v-model="detail.next_stage.description"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="detail.process_stage" cols="6">
                        <b-form-group
                            label="Process Stage"
                            label-for="process_stage"
                        >
                            <b-form-input
                                id="process_stage"
                                v-model="detail.process_stage + '%'"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Start Date"
                            label-for="start_date"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['start_date']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.start_date +
                                      ' | ' +
                                      detail.data.start_date_updated_at +
                                      '.'
                            "
                        >
                            <b-form-datepicker
                                id="start_date"
                                v-model="detail.start_date"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Salary"
                            label-for="salary"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['salary']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.salary +
                                      ' | ' +
                                      detail.data.salary_updated_at +
                                      '.'
                            "
                        >
                            <cleave
                                id="salary"
                                v-model="detail.salary"
                                class="form-control"
                                :raw="false"
                                :options="{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                }"
                                placeholder="10,000"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Fee Type"
                            label-for="fee_rate_type"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['fee_type']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.fee_type +
                                      ' | ' +
                                      detail.data.fee_type_updated_at +
                                      '.'
                            "
                            invalid-feedback="This field is required"
                            :state="isValidForm()"
                        >
                            <b-form-input
                                id="fee_rate_type"
                                v-model="detail.fee_type"
                                placeholder="%"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['fee_rate']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.fee_rate +
                                      ' | ' +
                                      detail.data.fee_rate_updated_at +
                                      '.'
                            "
                            invalid-feedback="This field is required"
                            :state="isValidForm()"
                        >
                            <label for="fee-rate">Fee/Rate</label>
                            <b-input-group :append="detail.fee_type">
                                <b-form-input
                                    v-model="detail.fee_rate"
                                    placeholder="20"
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Revenue"
                            label-for="revenue"
                            :description="
                                Object.keys(detail.data).length === 0 ||
                                !detail.data['revenue']
                                    ? ''
                                    : 'Last update by ' +
                                      detail.data.revenue +
                                      ' | ' +
                                      detail.data.revenue_updated_at +
                                      '.'
                            "
                        >
                            <cleave
                                id="revenue"
                                v-model="detail.revenue"
                                class="form-control"
                                :raw="false"
                                :options="{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                }"
                                placeholder="50,000"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
        <b-modal
            id="edit-revenue-btn"
            ref="edit-revenue-btn"
            :title="
                (detailRevenue.project_revenue === '' ||
                detailRevenue.project_revenue === null
                    ? 'Add'
                    : 'Edit') + ' Project Revenue'
            "
            ok-title="Save"
            size="sm"
            centered
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            :ok-disabled="isValid()"
            @ok="handleRevenueOk"
        >
            <form
                ref="form2"
                @submit.stop.prevent="handleRevenueOk"
                v-if="Object.entries(detailRevenue).length > 0"
            >
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Revenue" label-for="number">
                            <cleave
                                id="number"
                                disabled
                                v-model="detailRevenue.revenue_rf"
                                class="form-control"
                                :raw="false"
                                :options="{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                }"
                                placeholder="10,000"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Months" label-for="number">
                            <cleave
                                id="number"
                                v-model="detailRevenue.months"
                                class="form-control"
                                :raw="false"
                                :options="{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                }"
                                placeholder="6"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group
                            label="Project Revenue"
                            label-for="number"
                            :description="
                                Object.keys(detailRevenue.data).length === 0 ||
                                !detailRevenue.data['project_revenue']
                                    ? ''
                                    : 'Last update by ' +
                                      detailRevenue.data.project_revenue +
                                      ' | ' +
                                      detailRevenue.data
                                          .project_revenue_updated_at +
                                      '.'
                            "
                        >
                            <cleave
                                id="number"
                                v-model="detailRevenue.project_revenue"
                                class="form-control"
                                :raw="false"
                                :options="{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                }"
                                placeholder="100,000.00"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
        <b-modal
            id="edit-note-btn"
            ref="edit-note-btn"
            title="Notes"
            ok-title="Post"
            size="lg"
            centered
            cancel-variant="outline-secondary"
            :ok-disabled="isValid()"
            @ok="handleNoteOk"
        >
            <form
                v-if="Object.entries(note).length > 0"
                ref="form"
                @submit.stop.prevent="handleNoteOK"
            >
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Add Note" label-for="note">
                            <quill-editor
                                v-model="note.message"
                                :options="{
                                    theme: 'snow',
                                }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                        <div class="modal-scroll">
                            <app-timeline>
                                <app-timeline-item
                                    v-for="item in notes"
                                    :key="item.id"
                                    :title="
                                        item.created_by.first_name +
                                        ' ' +
                                        item.created_by.last_name +
                                        ' wrote a note:'
                                    "
                                    :subtitle="item.message"
                                    :time="item.updated_at"
                                    :html="true"
                                    :button-icon="hideButton(item)"
                                    variant="success"
                                    tooltip="Delete"
                                    @action="deleteNote(item.id)"
                                />
                            </app-timeline>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </div>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<script>
import Report from "@/api/services/Report.js"
import store from "@/store/index"
import {
BAvatar,
BBadge,
BButton,
BCol,
BContainer,
BDropdown,
BDropdownItem,
BFormCheckbox,
BFormDatepicker,
BFormGroup,
BFormInput,
BFormSelect,
BFormSpinbutton,
BFormTextarea,
BInputGroup,
BModal,
BPagination,
BProgress,
BRow,
VBModal,
VBTooltip,
} from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

import Cleave from "vue-cleave-component"
import flatPickr from "vue-flatpickr-component"
import VueSlider from "vue-slider-component"
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us"
// eslint-disable-next-line
import "quill/dist/quill.core.css"
// eslint-disable-next-line
import "quill/dist/quill.snow.css"

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { quillEditor } from "vue-quill-editor"

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BButton,
        BModal,
        BProgress,
        BContainer,
        BRow,
        BCol,
        vSelect,
        BFormTextarea,
        BFormDatepicker,
        Cleave,
        BInputGroup,
        flatPickr,
        VueSlider,
        quillEditor,
        BFormSpinbutton,
        ToastificationContent,
        AppTimeline,
        AppTimelineItem,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            selected: [],
            pageLength: 100,
            dir: false,
            columns: [
                {
                    label: "ID",
                    field: "id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search ID",
                    },
                    hidden: true,
                },
                {
                    label: "Job ID",
                    field: "job_id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Job ID",
                    },
                    hidden: true,
                },
                {
                    label: "Recruiter",
                    field: "recruiter.name",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Recruiter",
                    },
                    hidden: false,
                    // type: 'select',
                    // selected: '',
                    // options: []
                },
                {
                    label: "Candidate",
                    field: "candidate",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Candidate",
                    },
                    hidden: false,
                },
                {
                    label: "Client",
                    field: "client",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Client",
                    },
                    hidden: false,
                },
                {
                    label: "Role",
                    field: "role",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Role",
                    },
                    hidden: false,
                },
                {
                    label: "Sent Date",
                    field: "sent_date",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Sent Date",
                        filterFn: this.dateRangeFilter,
                    },
                    hidden: false,
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: "dd-MM-yyyy",
                },
                {
                    label: "Status",
                    field: "status.description",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Status",
                    },
                    hidden: false,
                },
                {
                    label: "Current Stage",
                    field: "current_stage.description",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Current Stage",
                    },
                    hidden: false,
                },
                {
                    label: "Next Stage",
                    field: "next_stage.description",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Next Stage",
                    },
                    hidden: false,
                },
                {
                    label: "Process Stage",
                    field: "process_stage",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Process Stage",
                        filterFn: this.numberRangeFilter,
                    },
                    hidden: false,
                    type: "number",
                    model: 1,
                    value: [0, 100],
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    label: "Start Date",
                    field: "start_date",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Sent Date",
                        filterFn: this.dateRangeFilter,
                    },
                    hidden: false,
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: "dd-MM-yyyy",
                },
                {
                    label: "Salary",
                    field: "salary",
                    type: "number",
                    filterOptions: {
                        filterFn: this.numberFilter,
                        enabled: true,
                        placeholder: "Search Salary",
                    },
                    hidden: false,
                },
                {
                    label: "Type",
                    field: "department.description",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Type",
                    },
                    hidden: false,
                },
                {
                    label: "Fee Rate",
                    field: "fee_rate",
                    type: "number",
                    filterOptions: {
                        filterFn: this.numberFilter,
                        enabled: true,
                        placeholder: "Search Fee Rate",
                    },
                    hidden: false,
                },
                {
                    label: "Value Type",
                    field: "fee_type",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Value Type",
                    },
                    hidden: true,
                },
                {
                    label: "Revenue",
                    field: "revenue",
                    type: "number",
                    filterOptions: {
                        filterFn: this.numberFilter,
                        enabled: true,
                        placeholder: "Search Revenue",
                    },
                    hidden: false,
                },
                {
                    label: "Project Revenue",
                    field: "project_revenue",
                    filterOptions: {
                        filterFn: this.numberFilter,
                        enabled: true,
                        placeholder: "Search Project Revenue",
                    },
                    hidden: false,
                },
                {
                    label: "Note",
                    field: "note",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Note",
                    },
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                },
            ],
            rows: [],
            searchTerm: "",
            total: 0,
            isLoading: false,
            detail: {},
            recruiters: [],
            form: {},
            query: "",
            departments: [],
            detailRevenue: {},
            ordering: "",
            note: {},
            notes: [],
        }
    },
    computed: {
        // statusVariant() {
        //   const statusColor = {
        //     /* eslint-disable key-spacing */
        //     Current      : 'light-primary',
        //     Professional : 'light-success',
        //     Rejected     : 'light-danger',
        //     Resigned     : 'light-warning',
        //     Applied      : 'light-info',
        //     /* eslint-enable key-spacing */
        //   }

        //   return status => statusColor[status]
        // },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    watch: {
        searchTerm: function (value) {
            this.loadData(1, this.ordering, "", value)
        },
        "detailRevenue.revenue_rf": function (value) {
            if (value && this.detailRevenue.months) {
                this.detailRevenue.project_revenue =
                    parseFloat(value.replace(",", "").replace(".", ",")) *
                    this.detailRevenue.months
            }
        },
        "detailRevenue.months": function (value) {
            if (value && this.detailRevenue.revenue_rf) {
                this.detailRevenue.project_revenue =
                    parseFloat(value) *
                    parseFloat(
                        this.detailRevenue.revenue_rf
                            .replace(",", "")
                            .replace(".", ",")
                    )
            }
        },
    },
    methods: {
        hideButton(item) {
            if (item.created_by.id === this.$store.state.portal.profile.id) {
                return "Trash2Icon"
            }
            return ""
        },
        deleteNote(id) {
            this.$loading(true)
            Report.deleteNote(id).then((response) => {
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            icon: "CheckCircleIcon",
                            text: "Your note has been successfully deleted!",
                            variant: "success",
                        },
                    },
                    {
                        position: "top-center",
                    }
                )
                Report.getNote(this.note.report).then((r) => {
                    this.notes = r.data.results
                    this.$loading(false)
                })
            })
        },
        download() {
            this.loadData(
                1,
                this.ordering,
                this.query.includes("?page=")
                    ? this.query.slice(7)
                    : this.query,
                "",
                true
            )
            // var file = window.open('http://localhost:8000/api/v1/reports/export/finance/' + this.query.slice(7))
            // file.close()
        },
        isValidForm() {
            if (
                this.detail.fee_rate === "0.00" &&
                this.detail.fee_type === ""
            ) {
                return true
            }
            if (
                this.detail.fee_rate !== "0.00" &&
                this.detail.fee_rate !== "" &&
                this.detail.fee_type !== ""
            ) {
                return true
            }
            return false
        },
        isValid() {
            if (
                this.detail.fee_rate === "0.00" &&
                this.detail.fee_type === ""
            ) {
                return false
            }
            if (
                this.detail.fee_rate !== "0.00" &&
                this.detail.fee_rate !== "" &&
                this.detail.fee_type !== ""
            ) {
                return false
            }
            return true
        },
        clear() {
            // this.query = ''
            // this.loadData()
            this.$router.go()
        },
        updateFiltersOnKeyup(column, value) {
            // if the trigger is enter, we don't filter on keyup
            if (column.filterOptions.trigger === "enter") return
            this.updateFilters(column, value)
        },
        updateFiltersOnEnter(column, value) {
            if (this.timer) clearTimeout(this.timer)
            this.updateFiltersImmediately(column.field, value)
        },
        updateFiltersImmediately(field, value) {
            this.$set(this.columnFilters, this.fieldKey(field), value)
            this.$emit("filter-changed", this.columnFilters)
        },
        updateFilters(column, value) {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.updateFiltersImmediately(column.field, value)
            }, 400)
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleNoteOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleNoteSubmit()
        },
        handleRevenueOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleRevenueSubmit()
        },
        handleNoteSubmit() {
            this.$loading(true)
            // this.$nextTick(() => {
            //   this.$bvModal.hide('edit-note-btn')
            // })
            Report.setNote(this.note)
                .then((response) => {
                    this.note.message = ""
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "Your note have been successfully posted!",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                    Report.getNote(this.note.report).then((r) => {
                        this.notes = r.data.results
                        this.$loading(false)
                    })
                })
                .catch(() => {
                    this.$loading(false)
                })
        },
        handleRevenueSubmit() {
            this.$loading(true)
            this.$nextTick(() => {
                this.$bvModal.hide("edit-revenue-btn")
            })
            Report.putFinanceReport({
                id: this.detailRevenue.id,
                project_revenue: this.detailRevenue.project_revenue
                    ? this.detailRevenue.project_revenue.replace(",", "")
                    : "",
            }).then((response) => {
                this.$loading(false)
                this.loadData()
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            icon: "CheckCircleIcon",
                            text: "Your changes have been successfully saved!",
                            variant: "success",
                        },
                    },
                    {
                        position: "top-center",
                    }
                )
            })
        },
        handleSubmit() {
            this.$loading(true)
            this.$nextTick(() => {
                this.$bvModal.hide("edit-btn")
            })
            Report.putFinanceReport({
                id: this.detail.id,
                recruiter: this.detail.recruiter.name.id
                    ? this.detail.recruiter.name.id
                    : this.detail.recruiter.id,
                department: this.detail.department.description.id
                    ? this.detail.department.description.id
                    : this.detail.department.id,
                role: this.detail.role,
                sent_date: this.detail.sent_date,
                start_date: this.detail.start_date,
                salary: this.detail.salary
                    ? this.detail.salary.replace(",", "")
                    : "",
                fee_rate: this.detail.fee_rate
                    ? this.detail.fee_rate.replace(",", "")
                    : "",
                revenue: this.detail.revenue
                    ? this.detail.revenue.replace(",", "")
                    : "",
                fee_type: this.detail.fee_type,
                note: this.detail.note,
            }).then((response) => {
                this.$loading(false)
                this.loadData()
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            icon: "CheckCircleIcon",
                            text: "Your changes have been successfully saved!",
                            variant: "success",
                        },
                    },
                    {
                        position: "top-center",
                    }
                )
            })
        },
        resetModal() {
            this.loadData()
        },
        edit(value) {
            this.detail = value
        },
        editNote(value) {
            Report.getNote(value.id).then((response) => {
                this.notes = response.data.results
            })
            this.note = {
                report: value.id,
                message: "",
            }
        },
        editRevenue(value) {
            this.detailRevenue = value
        },
        onPageChange(params) {
            this.loadData(params.currentPage, this.ordering, this.query)
        },
        onSortChange(params) {
            var field = params[0].field.replace(".", "__")
            if (params[0].type !== "asc") {
                field = "-" + field
            }
            if (field !== "action") {
                this.loadData(1, field)
                this.ordering = field
            }
        },
        onColumnFilter(params) {
            var query = ""
            for (var [key, value] of Object.entries(params.columnFilters)) {
                if (value !== "") {
                    if (key === "sent_date" || key === "start_date") {
                        var c = value.split(" to ")
                        if (c.length > 1) {
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__gte=" +
                                c[0]
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__lte=" +
                                c[1]
                        } else {
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__icontains=" +
                                c[0]
                        }
                    } else if (key === "process_stage") {
                        var result1 = value[0] === "" ? "0" : value[0]
                        if (value.length > 1) {
                            var result2 = value[1] === "" ? "100" : value[1]
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__gte=" +
                                result1
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__lte=" +
                                result2
                        } else {
                            query =
                                query +
                                "&" +
                                key.replace(".", "__") +
                                "__icontains=" +
                                result1
                        }
                    } else {
                        query =
                            query +
                            "&" +
                            key.replace(".", "__") +
                            "__icontains=" +
                            value
                    }
                }
            }
            this.query = query
            this.loadData(1, this.ordering, query)
        },
        onPerPageChange() {},
        cleanZero(value) {
            return !Number.isNaN(Number(value.replace(".00", ""))) &&
                Number(value.replace(".00", "")) !== 0
                ? Number(value.replace(".00", ""))
                : ""
        },
        dateRangeFilter(data, filterString) {
            let dateRange = filterString.split("to")
            let startDate = Date.parse(dateRange[0])
            let endDate = Date.parse(dateRange[1])
            return (data =
                Date.parse(data) >= startDate && Date.parse(data) <= endDate)
        },
        numberFilter(data, filterString) {
            return (data = String(data).includes(filterString))
        },
        numberRangeFilter(data, filterString) {
            let start = filterString[0] === "" ? 0 : Number(filterString[0])
            let end = filterString[1] === "" ? 100 : Number(filterString[1])
            return (data = data >= start && data <= end)
        },
        processStageVariant(value) {
            value = Number(value)
            if (value === 100) {
                return "success"
            } else if (value > 69) {
                return "warning"
            } else {
                return "primary"
            }
        },
        statusVariant(value) {
            var result = "light-info"
            switch (value) {
                case "In progress":
                    result = "light-warning"
                    break
                case "Hired":
                    result = "light-success"
                    break
                default:
                    result = "light-info"
            }
            return result
        },
        loadData(
            page = 1,
            ordering = "",
            query = "",
            search = "",
            download = false
        ) {
            this.$loading(true)
            Report.getFinance(page, ordering, query, search, download)
                .then((response) => {
                    if (download === true) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute("download", "finance_report.xlsx")
                        document.body.appendChild(link)
                        link.click()
                        return
                    }
                    var items = []
                    response.data.results.forEach((element) => {
                        var item = element
                        if (!element.recruiter) {
                            item.recruiter = {}
                        }
                        items.push(item)
                    })
                    this.rows = items
                    this.total = response.data.count
                    window.scrollTo(0, 0)
                    this.$loading(false)
                })
                .catch((error) => {
                    this.$loading(false)
                })
        },
        getRecruiters() {
            this.$loading(true)
            Report.getRecruiters().then((response) => {
                this.recruiters = response.data.results.filter((element) => {
                    var item = {}
                    item = element
                    item.title = element.name
                    return item
                })
                this.$loading(false)
            })
        },
        getDepartments() {
            this.$loading(true)
            Report.getDepartments().then((response) => {
                this.departments = response.data.results.filter((element) => {
                    var item = {}
                    item = element
                    item.title = element.description
                    return item
                })
                this.$loading(false)
            })
        },
    },
    created() {
        this.loadData()
        this.getRecruiters()
        this.getDepartments()
    },
}
</script>
